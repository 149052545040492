<template>
    <div class="col-sm-12 col-lg-5 text-justify">
        <div class="row">
            <div class="col-sm-12">
                <p class="biography">
                    Maud Gourdon (b. 1991, France) is a Brussels-based visual artist who works with publications, texts, drawings and sculptures that culminate in installations. Gourdon's installations tend to be rooted in intimate stories and familiar objects, which she interweaves with wordplay, line play and multiple other forms. Her research leads her through history and past traditions, where she looks for possible connections. This process takes place through analogy, homophony, homography and, above all, through coincidences such as a slip of the pen or of the tongue. For the artist, this specific way of distinguishing connections and relationships serves as a model for her narratives.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <p class="biography">
                    Gourdon is currently in residence at HISK (Ghent, BE) (2022-2023). She is a current member of Level Five zvw (Brussels, BE) and a former resident of M-Museum (Leuven, BE).
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <p class="biography">
                    Gourdon obtained her first master's degree in communication at La Hear in Strasbourg (FR) (2014) and her second master's degree in visual arts at KASK in Ghent (BE) (2017). Her work has been exhibited at Beige (Brussels, BE); Rile* (Brussels, BE); M-Museum (Leuven, BE); Cas-co (Leuven, BE); Pavillon Charles Vandenhove (Ghent, BE); Le Transpalette (Bourges, FR); S.M.A.K (Ghent, BE); Le 19; CRAC (Montbelliard, FR); Damien and the Love Guru (Brussels, BE) and Kiosk (Ghent, BE).
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <p class="biography">
                    Her first solo exhibition "A.Mon Seul Désir.R" (2016) was presented at MAP (Ghent, BE). Her project "Peinée l'eau peut" (2017) was awarded the "Legaten van het KASK" (2018) and was part of the selection of the "Prijs van de Vrienden van het S.M.A.K" (2017). <br />
                    Her project "A flower is Speaking to a Dog" (2019), a text-based installation was published by BOEKS (Ghent, BE), as part of a solo exhibition (2019). The project was also awarded the "Prix de la Ville de Bruxelles" 2021. <br />
                    Her next solo exhibition "Cataplasme Celebration" will take place in November 2022 at CENTRALE in Brussels. 
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <p class="biography">
                    Her texts and drawings have been published in Kunstletters (BE), X-Rivista (IT,BE) and H ART (BE). More recently, her text "Seven Short Stories About Nobody" was included in the publication "Family Nexus" (2021) (KIOSK, Ghent, BE), with the artists Sophie Nys, Leila Peacocks and Liene Aerts.
                </p>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-sm-12">
            <a class='underlined' href="https://storage.gra.cloud.ovh.net/v1/AUTH_0697890e15ad4098b140de1fed916171/maudgourdon.com/files/PM_2021_Maud_Gourdon_pages.pdf">Article by Est-elle Spoto for Prix Mediatine</a><br />
            <a class='underlined' href="https://storage.gra.cloud.ovh.net/v1/AUTH_0697890e15ad4098b140de1fed916171/maudgourdon.com/files/TherolinguisticTale/Interview_M_Magazine.pdf">Interview M-Magazine</a><br />
            <a class='underlined' href="https://storage.gra.cloud.ovh.net/v1/AUTH_0697890e15ad4098b140de1fed916171/maudgourdon.com/files/ConversationWithBasBlaasse.pdf">Conversation with Bas Blaasse</a>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-sm-12 nospace">
                <h3>Contact</h3>
                <p class="contact-email"><a class="back2top" href="mailto:info@maudgourdon.com">info@maudgourdon.com</a></p>
            </div>
        </div>
    </div>
</template>

<style lang="css" scoped>
a, a:active, a:hover, a:visited {
    color: #000;
}
.nospace {
    line-height: 10px;
}
</style>
