<template>
    <div class="col-sm-12 col-lg-5 text-justify">
        <div class="row">
            <div class="col-sm-12">
                <h1>Error 404 - Page not found</h1>
                <p>
                    The page you tried to access does not exists.
                </p>
                <p>
                    <router-link to="/">Back to homepage</router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="css" scoped>
a, a:active, a:hover, a:visited {
    text-decoration: none;
    color: #000;
}
.nospace {
    line-height: 10px;
}
</style>
