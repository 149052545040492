import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '@/components/mg-homepage.vue'
import About from '@/components/mg-about.vue'
import Article from '@/components/mg-article.vue'
import Error404 from '@/components/mg-404.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/article/:id',
    name: 'article',
    component: Article,
  },
  {
    path: '/*',
    name: 'fallback-404',
    component: Error404,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
