<template>
  <div class="homepage row">
    <router-link
      v-for="article in ordre"
      :key="article.id + '-' + article.image"
      :to="'/article/' + article.id"
      class="clickable col-sm-12 col-lg-7 text-left"
    >
      <img
        :src="`${base}${get(article.id).images[article.image - 1]}`"
        :alt="get(article.id).title"
      />
      <br />
      <h3>{{ get(article.id).title }}</h3>
    </router-link>

    <div class="col-sm-12 col-lg-6 text-right pad-top">
      <a href="#app" class="back2top">Back to top</a>
    </div>
    <div class="col-sm-12 col-lg-6 text-left">&nbsp;</div>
    <div class="col-sm-12">&nbsp;</div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
const store = useStore();

const articles = store.state.articles;
const ordre = store.state.homepage;
const base = store.state.baseStorageUri;

function get(artId) {
  return articles.find((art) => art.id === artId);
}
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 0.5rem;
}
img {
  max-width: 550px;
  max-height: 550px;
}
.clickable {
  cursor: pointer;
  margin-bottom: 60px;
  box-sizing: border-box;
}

a,
a:visited,
a:hover,
a:active {
  color: #000;
  text-decoration: none;
}
</style>
