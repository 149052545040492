<template>
    <div class="row">
        <!-- Main image + title -->
        <div class="col-sm-12 row-no-padding text-left text">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <img
                                :src="`${base}${article.images[0]}`"
                                :alt="article.title" />
                        </td>
                        <td class="padding-left-15 text-align-bottom align-bottom">
                            <h3 class="m-t-lg-5">{{article.title}}</h3>
                            <p v-if="article.description"><i>{{ article.description }}</i></p>
                            <div class="w-450" v-html="article.long_description"></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-12 col-lg-5 other-images">
            <div v-for="id in article.images.length - 1" :key="id">
                <img
                    :src="`${base}${article.images[id]}`"
                    :alt="article.title" />
                <br />
            </div>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">&nbsp;</div>
        <div class="col-sm-12 col-lg-6 text-right pad-top">
            <a href="#app" class="back2top">Back to top</a>
        </div>
        <div class="col-sm-12 col-lg-6 pad-top text-left">
            &nbsp;
        </div>
    </div>
</template>

<script>
export default {
    methods: {
    },
    computed: {
        article() {
            console.log("getting article", this.$route.params.id, this.$store.state.articles);
            return this.$store.state.articles.find(a => String(a.id) === this.$route.params.id);
        },
        base() {
            return this.$store.state.baseStorageUri;
        }
    },
    mounted() {
        const thisArticle = this.$store.state.articles.find(a => String(a.id) === this.$route.params.id);
        if (!thisArticle) {
            this.$router.push('/not-found');
        }
        console.log("Component mounted");
    }
}
</script>


<style lang="scss" scoped>
img {
  max-width: 550px;
  max-height: 550px;
}

table {
    height: 100%;
    width: fit-content;
}
.extend {
    width: available;
}
.other-images img {
    margin-top: 60px;
}

.w-450 {
    padding-top: 5px;
    width: 450px;
}
h3 {
    margin-bottom: 0px;
    line-height: 13px;
    margin-top: 10px;
}
.pad-top {
    padding-top: 50px;
    padding-bottom: 50px;
}
.text {
    line-height: 18px;
}
.padding-left-15 {
    padding-left: 15px;
}
</style>
